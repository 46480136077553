<template>
	<Modal :showModal="show" @close="$emit('close')">
		<template v-slot:header>
			<div class="modal-header">
				<h3 class="modal-title text-white">Política de privacidad</h3>
				<button
				type="button"
				class="close text-white"
				data-dismiss="modal"
				aria-label="Close"
				>
				<span aria-hidden="true" @click="$emit('close')"
				>×</span
				>
			</button>
		</div>
		</template>
		<template v-slot:body>
			<div class="privacy-policy">
				<p class="mb5">
					Bienvenido a la Política de Privacidad de PROHIPOTECAS. En este
					documento se describe cómo recopilamos, almacenamos, usamos,
					protegemos y compartimos su información y con quién la compartimos.
				</p>

				<h3 class="font-weight-bold">
					¿Quién es el responsable del tratamiento de sus datos personales?
				</h3>
				<ul class="mb5">
					<li>Identidad: Finteca Tech SL</li>
					<li>Domicilio social: Plaça de Pau Vila 1, Esc. A, 2A</li>
					<li>C.I.F. Nº: B67055715</li>
					<li>Email: hola@prohipotecas.es</li>
					<li>Teléfono: 900732023</li>
				</ul>
				<h3 class="font-weight-bold">
					¿Con qué finalidad tratamos sus datos personales?
				</h3>
				<p class="mb5">
					Tratamos la información que nos facilita el USUARIO con la finalidad
					de suscribir un acuerdo de colaboración para dar servicio a sus
					clientes a cambio de una comisión, así como para darles acceso a la
					Plataforma para que puedan registrar y dar seguimiento las operaciones
					de sus clientes que serán gestionados por PROHIPOTECAS.
				</p>
				<p class="mb5">
					En los formularios de la Plataforma, será necesario que el USUARIO
					cumplimente los campos que PROHIPOTECAS requiera como obligatorios ya
					que, en caso contrario, el USUARIO no podrá ser registrado en
					PROHIPOTECAS. El USUARIO debe comunicar a PROHIPOTECAS sus datos
					personales actuales a fin de que la información contenida en sus
					ficheros esté actualizada y sin errores.
				</p>
				<h3 class="font-weight-bold">
					¿A qué destinatarios se comunicarán sus datos?
				</h3>
				<p class="mb5">
					Los datos podrán comunicarse al Banco Sabadell para el pago de las
					facturas emitidas por el Colaborador. Asimismo, se podrán ceder los
					datos a Nuclio VB3, S.L. para la contabilización y el pago de las
					facturas.
				</p>
				<h3 class="font-weight-bold">
					¿Por cuánto tiempo conservaremos sus datos?
				</h3>
				<p class="mb5">
					Los datos personales proporcionados se conservarán durante el plazo de
					diez (10) años de conformidad con la normativa vigente.
				</p>
				<h3 class="font-weight-bold">
					¿Cuál es la legitimación para el tratamiento de sus datos?
				</h3>
				<p class="mb5">
					La base legal para el tratamiento de datos es el consentimiento del
					usuario prestado mediante la aceptación de la cláusula de tratamiento
					de datos, así como la ejecución de la relación contractual.
				</p>
				<h3 class="font-weight-bold">
					¿Cuáles son sus derechos cuando nos facilita sus datos personales?
				</h3>
				<p class="mb5">
					El usuario puede ejercer su derecho de acceso a los datos personales,
					así como solicitar la rectificación de los datos inexactos o, en su
					caso, solicitar su supresión cuando los datos ya no sean necesarios
					para los fines que fueron recogidos. También podrá solicitar la
					limitación, portabilidad y oposición del tratamiento de sus datos, en
					determinadas circunstancias y por motivos relacionados con su
					situación particular.
				</p>
				<p class="mb5">
					Igualmente, tiene derecho a revocar su consentimiento en cualquier
					momento sin que ello afecte de forma retroactiva al tratamiento de
					datos personales realizado hasta ese momento.
				</p>
				<p class="mb5">
					El USUARIO podrá ejercitar los derechos referidos anteriormente, en
					los términos y condiciones previstos en la legislación vigente, en el
					domicilio social de PROHIPOTECAS o solicitarlo mediante el envío de
					correo electrónico a: hola@prohipotecas.es
				</p>
				<p class="mb5">
					En el supuesto de que no obtenga una respuesta satisfactoria y desee
					formular una reclamación u obtener mayor información al respecto de
					cualquiera de estos derechos, puede acudirse a la Agencia Española de
					Protección de Datos (www.agpd.es - C/ Jorge Juan, 6 de Madrid).
				</p>
				<p class="mb5">
					Los datos personales son muy importantes para PROHIPOTECAS, por ello
					se compromete a tratarlos con la máxima confidencialidad y discreción,
					así como a implementar todas aquellas medidas de seguridad que
					considere oportunas y razonables.
				</p>
				<p class="mb5">
					En este sentido, PROHIPOTECAS declara y garantiza que ha establecido
					todos los medios técnicos a su alcance para evitar la pérdida, mal
					uso, alteración, acceso no autorizado y robo de los datos facilitados
					por los USUARIOS. Asimismo, PROHIPOTECAS revisa constantemente sus
					medidas de seguridad para asegurar que se encuentren siempre al día.
				</p>
				<p class="mb-0">
					Nos reservamos el derecho a eliminar cualesquiera contenidos que
					presuntamente vulneren derechos o de cualquier otro modo resulten
					ilícitos sin aviso previo y a nuestra entera discreción. Llegado el
					caso, procederemos asimismo a la cancelación de la cuenta de USUARIO
					si quedara establecido que este es un infractor reincidente.
				</p>
			</div>
		</template>
	</Modal>
</template>

<script>
	import { defineAsyncComponent } from 'vue';
	
	export default {
		name: 'PrivacyModal',
	    props: {
		    show: {
		      type: Boolean,
		      required: true,
		    }
		},
	    components: {
		    Modal: defineAsyncComponent(() => import('@/components/Modal.vue'))
		},
	};
</script>